@font-face {
  font-family: 'Vazirmatn';
  src: url('./assets/font/Vazirmatn-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Vazirmatn';
  src: url('./assets/font/Vazirmatn-SemiBold.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Vazirmatn';
  src: url('./assets/font/Vazirmatn-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

* {
  font-family: Vazirmatn, sans-serif !important;
}

body {
  margin: 0;
}
